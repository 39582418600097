<template>
  <v-container fill-height>
    <v-row class="fill-height" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="5" lg="4" xl="3">
        <CustomChat
          ref="customChat"
          :chat="chat"
          :attendant="{
            name: 'Chat Bot',
            icon: 'mdi-android',
          }"
          :finalizeLabel="'Cancelar Atendimento'"
          :height="'82.5vh'"
          :disabled="
            readMode || autofill || _currentIsSelectOption || loadingMessage
          "
          :hideAttach="true"
          @send-message="sendMessage($event)"
          @finalize="handleFinalize()"
        />
      </v-col>
    </v-row>

    <SelectorDialog
      v-model="selectorDialog"
      :loading="loading"
      @submit="dialogSubmit"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { postDialog } from "@/services/dialogflow";
import { updateAttendance } from "@/services/attendance";
import { handleAlert, handleFinishAttendance } from "@/utils";
import SelectorDialog from "./partials/selectorDialog/SelectorDialog.vue";
import CustomChat from "@/components/customChat/CustomChat";

import moment from "moment";
moment.locale("pt-BR");

import _ from "lodash";
export default {
  name: "ChatBot",

  components: {
    CustomChat,
    SelectorDialog,
  },

  data() {
    return {
      loading: false,
      loadingMessage: false,
      readMode: false,
      started: false,
      autofill: false,
      selectorDialog: false,
      chat: {
        messages: [
          {
            me: false,
            createdAt: moment(),
            message: `Olá, bem-vindo(a) ao nosso ${process.env.VUE_APP_DOCUMENT_TITLE.replace(
              "-",
              "do"
            )}.`,
          },
        ],
      },
      attendanceData: {
        name: "",
        cpf: "",
        email: "",
        phone: "",
        comarcaID: "",
        varaID: "",
        deskID: "",
      },
      payload: {},
    };
  },

  created() {
    if (!this.attendance) {
      return this.$router.push({ path: "/home" });
    }
  },

  computed: {
    ...mapState("socket", ["attendance", "messages"]),
    ...mapState(["preSelection"]),

    _currentIsSelectOption() {
      const { length = 0 } = this.chat.messages;
      return length === 0 || !!this.chat.messages[length - 1]?.options;
    },
  },

  beforeMount() {
    if (this.messages?.length) {
      this.chat.messages = this.messages;

      const lastMessage = this.chat.messages.length
        ? this.chat.messages[this.chat.messages.length - 1]
        : null;

      if (this.attendance.requester) {
        this.payload.requester = this.attendance.requester;
      }

      if (lastMessage?.next === "select-entities") {
        this.handleEntitiesDialog();
      }
    } else {
      this.sendMessage({
        key: null,
        message: "Sim, podemos seguir",
        value: "Sim, podemos seguir",
      });
    }
  },

  mounted() {
    document.addEventListener("touchstart", this.handleTouchStart);
    document.addEventListener("touchmove", this.handleTouchMove, {
      passive: false,
    });
  },

  beforeDestroy() {
    document.removeEventListener("touchstart", this.handleTouchStart);
    document.removeEventListener("touchmove", this.handleTouchMove);
  },

  methods: {
    handleAlert,

    ...mapActions("socket", [
      "requestAttendance",
      "cancelAttendance",
      "setAttendance",
      "setInQueue",
      "dialogAttendance",
      "setMessages",
    ]),

    async sendMessage(event) {
      try {
        this.loadingMessage = true;

        if (!this.started) {
          this.started = true;
        }

        if (event.key) {
          _.set(this.payload, event.key, event.value || event.message);
        }

        this.setChatMessage(event.message, true);

        // send to socket user message
        this.dialogAttendance({
          data: {
            protocol: this.attendance.protocol,
            message: {
              author: "user",
              text: event.message,
            },
          },
          callback: (error, data) => {
            if (error) {
              console.log("sendMessage:error", error);
              throw new Error(error);
            }

            this.setAttendance(data);
          },
        });

        await updateAttendance(this.attendance._id, this.payload);

        const payload = {
          text: event.message,
        };

        const { data } = await postDialog(this.attendance.protocol, payload);

        // send to socket bot message
        this.dialogAttendance({
          data: {
            protocol: this.attendance.protocol,
            message: {
              author: "chatbot",
              text: data.phrase,
            },
          },
          callback: (error, data) => {
            if (error) {
              console.log("sendMessage:error", error);
              throw new Error(error);
            }

            this.setAttendance(data);
          },
        });

        if (data.phrase) {
          let options = [];

          if (data.type === "select")
            options = data.options?.map((el) => ({
              value: el.key,
              label: el.value,
            }));
          else
            options = data.options?.map((el) => ({
              value: el._id,
              label: el.name,
            }));

          if (data.next === "select-entities") {
            this.handleEntitiesDialog(data.next);

            return;
          }

          this.setChatMessage(
            data.phrase,
            false,
            options,
            data.mask,
            data.rules,
            data.key,
            data.next
          );
        }

        if (data.next === "finish") {
          this.readMode = true;
        }

        if (event.next === "queue") {
          // Send Emit to Request attendance on Socket Server
          await this.requestAttendance({
            data: {
              protocol: this.attendance.protocol,
              deskID: this.attendance.desk._id,
            },
            callback: (error, data) => {
              console.log("requestAttendance:data", data);

              if (error) {
                console.log("requestAttendance:error", error);
                throw new Error(
                  "Falha ao tentar solicitar atendimento. Tente novamente"
                );
              }

              // Update Attendance on Store
              this.setAttendance(data);
              this.readMode = true;

              this.setInQueue(true);
              this.setMessages([]);
            },
          });
        }

        if (this.autofill && data.key.includes("requester")) {
          const keys = data.key.replace("requester", "user").split(".");
          this.$refs.customChat.handleAutofill(this[keys[0]][keys[1]]);
          return;
        }

        if (this.autofill) this.autofill = false;
      } catch (error) {
        if (
          error.data.details[0].message ===
          '"requester.email" must be a valid email'
        ) {
          this.handleAlert("E-mail inválido ou incorreto", "error");
        } else
          this.handleAlert(
            error.response?.data?.message || error.data.message,
            "error"
          );
      } finally {
        this.loadingMessage = false;
      }
    },

    async dialogSubmit(event) {
      try {
        this.loading = true;

        this.payload = {
          requester: this.payload.requester,
          deskID: this.preSelection?.deskID || event?.desks?._id,
          varaID: this.preSelection?.subgroupID || event?.subgroups?._id,
          comarcaID: this.preSelection?.groupID || event?.groups?._id,
          attendanceFormat: "video",
        };

        await updateAttendance(this.attendance._id, this.payload);

        await this.requestAttendance({
          data: {
            protocol: this.attendance.protocol,
            deskID: this.payload.deskID,
          },
          callback: (error, data) => {
            console.log("requestAttendance:data", data);

            if (error) {
              console.log("requestAttendance:error", error);
              throw new Error(
                "Falha ao tentar solicitar atendimento. Tente novamente"
              );
            }

            // Update Attendance on Store
            this.setAttendance(data);
            this.setInQueue(true);
            this.setMessages([]);
          },
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    handleEntitiesDialog(next) {
      this.readMode = true;

      if (
        this.preSelection?.groupID &&
        this.preSelection?.subgroupID &&
        this.preSelection?.deskID
      ) {
        this.dialogSubmit();
        return;
      }

      this.selectorDialog = true;

      if (next) {
        this.chat.messages[this.chat.messages.length - 1].next = next;
        this.setMessages(this.chat.messages);
      }
    },

    handleStartChat(event) {
      this.setAttendance(event);
      this.$router.push({ path: `/attendance/${event._id}` }).catch(() => {});
    },

    setChatMessage(message, me, options, mask, rules = [], key, next) {
      this.chat.messages.push({
        createdAt: moment(),
        message,
        me,
        options,
        mask,
        rules,
        key,
        next,
      });

      this.setMessages(this.chat.messages);
    },

    handleFinalize() {
      this.handleFinishAttendance();

      this.$router.push({ path: "/home" }).catch(() => {});
    },

    handleTouchStart(event) {
      // Salva o ponto inicial do toque
      this.startY = event.touches[0].clientY;
    },

    handleTouchMove(event) {
      const currentY = event.touches[0].clientY;

      // Verifica se o movimento é para baixo e na borda superior da página
      if (currentY > this.startY && window.scrollY === 0) {
        event.preventDefault();
      }
    },

    handleFinishAttendance,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
